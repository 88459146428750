import React, { useEffect } from "react";

const GoogleAdSense = ({
  className = "adsbygoogle",
  client = "ca-pub-1034911615711188",
  slot = "8379232673",
  format = "auto",
  responsive = "true",
  layoutKey = ""
}) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
      console.log("Advertise is pushed");
    } catch (e) {
      if (process.env.NODE_ENV !== "production")
        console.error("AdvertiseError", e);
    }
  }, []);
  return (
    <ins
      className={className}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      data-ad-layout-key={layoutKey}
    />
  );
};

export default GoogleAdSense;
