import parse from 'html-react-parser';
import GoogleAdSense from './googleAdSense';
import './cheatsheet-section.scss';

interface CheatsheetSectionProps {
  title?: string;
  subtitle?: string;
  backgroundClass?: string;
  titleClass?: string;
  titleElement?: string;
  subtitleClass?: string;
}

export default function CheatsheetSection({title, subtitle, backgroundClass, titleClass, titleElement, subtitleClass}: CheatsheetSectionProps) {
  
  return <section>
    <div className='section-container'>
      <div className={`section-item ${backgroundClass ? backgroundClass : ""}`}>
        <div className={`section-title ${titleClass ? titleClass : ""}`}>
            {title ? parse(title) : null}
        </div>
        <div className={`section-subtitle ${subtitleClass ? subtitleClass : ""}`}>
          <p>{subtitle ? parse(subtitle) : null}</p>
          {backgroundClass === "google-ads" ? <GoogleAdSense/> : null}
        </div>
      </div>
    </div>
  </section>;
}
