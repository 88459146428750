import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './index.scss';
import App from './app';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MJ7DPRW3'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Design Cheatsheet for Non-Designers</title>
        <meta name="description" content="Make your own design for plausible applications, websites, reports, and slides."/>
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Design Cheatsheet for Non-Designers"/>
        <meta property="og:description" content="Make your own design for plausible applications, websites, reports, and slides." />
        <meta property="og:url" content={window.location.origin + window.location.pathname} />
        <meta property="og:image" content={window.location.origin + "/designcheatsheet-logo-with-margin-1800x500.png"} />
        <meta property="twitter:title" content="Design Cheatsheet for Non-Designers" />
        <meta property="twitter:description" content="Make your own design for plausible applications, websites, reports, and slides." />
        <meta property="twitter:url" content={window.location.origin + window.location.pathname} />
        <meta property="twitter:image" content={window.location.origin + "/designcheatsheet-logo-with-margin-1200x630.png"} />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
