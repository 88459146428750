import './navigation.scss';

export default function Navigation() {
    return <nav>
      <div className='nav-container'>
        <div className='nav-item nav-logo'>
          <a href={process.env.PUBLIC_URL + '/#'} aria-label='Design Cheatsheet Home.'>
            <img src={process.env.PUBLIC_URL + '/logo.svg'} className='logo' alt='Design Cheatsheet Logo.' title='Design Cheatsheet Logo.' loading="lazy" height='32px' width='156px' />
          </a>
        </div>
      </div>
    </nav>;
}
