import React from 'react';
import Navigation from './features/navigation';
import Footer from './features/footer';
import CheatsheetSection from './features/cheatsheet-section';
import './app.scss';

function App() {
  return (
    <div className='wrapper'>
      <Navigation />
      <header>
        <div className='header-container'>
          <div className='header-item title'>
            <h1>Design<br></br>Cheatsheet<br></br>For Non-<br></br>Designers</h1>
          </div>
          <div className='header-item subtitle'>
            <h2>
            Make your own design for<br></br>plausible applications, websites, reports, and slides.
            </h2>
          </div>
        </div>
      </header>
      <CheatsheetSection 
        title={"<h2>Content Over Design</h2>"} 
        subtitle={"Design is not only about<br/>the visual appearance.<br/>Explicit, concise, consistent content is<br/>more important<br/>than the design itself."}
      />
      <CheatsheetSection 
        title={"<h2>However,<br/>the design communicates<br/>core values well.</h2>"} 
        subtitle={"It can be a powerful weapon."}
      />
      <CheatsheetSection 
        title={"<h2>Well-designed means<br/>Well-communicatable.</h2>"} 
        subtitle={"+ Visually."}
      />
      <CheatsheetSection 
        title={"<h2>Put just one thing<br/>in one place.</h2>"} 
        subtitle={"No logos, No page numbers.<br/>People don't care."}
      />
      <CheatsheetSection 
        title={"<h2>Even if it is web or apps,<br/>just put one thing<br/>in the main.</h2>"}
        subtitle={"Not like this."}
        backgroundClass={"browser-mockup"}
      />
      <CheatsheetSection 
        title={"<h2>For international<br/>on screen,<br/>then Noto font.<br/><br/><span class='inter-font-family'>For Cyrillic, Greek, and Latin<br/>on screen,<br/>then Inter font.</span></h2>"} 
        subtitleClass={"inter-font-family"}
      />
      <CheatsheetSection 
        title={"<h2>Let's break with Ads.</h2>"}
        subtitle={"Can't you see?<br/>Oh, that was so unfortunate.<br/>Jump to the next section."}
        backgroundClass={"google-ads"}
      />
      <CheatsheetSection 
        title={"<h2>The line-height<br/>for the title is 110%,<br/>for the body is 150%.</h2>"}
      />
      <CheatsheetSection 
        title={"<h3 class='section-title-list'>The title looks quite empty<br/>even if the line spacing is<br/>slightly widened.</span></h3><h3 class='section-title-list'>This is 110%.<br/>For large titles,<br/>the line spacing<br/>must be narrow so<br/>they don't look empty.</h3><h3 class='section-title-list'>If it is narrower,<br/>the letters may overlap.</h3>"}
        backgroundClass={"line-height"}
      />
      <CheatsheetSection 
        subtitle={"<span class='section-subtitle-list'>In this sentence, the line height is 200%.<br/>If the line height in the text is too wide, the lines may appear unrelated.</span><span class='section-subtitle-list'>The line height in this sentence is 150%.<br/>It is easiest to view the text on the screen with this line height.<br/>Unlike the title, each letter in the text is small, so it needs to be spread out a little more.</span><span class='section-subtitle-list'>The line height in this sentence is 100%. If the line height is too narrow, it can frustrate readers.<br/>It's better to be moderate, neither too narrow nor too wide.</span>"} 
      />
      <Footer />
    </div>
  );
}

export default App;
